import { Intent } from '@blueprintjs/core';

export const Common: {[index: string]: any} = {
	GENRES: {
		ACTION: {
			name: 'Action',
			icon: 'hand-fist',
			id: '4d43349a-43a8-4755-9d52-41ece63ec5b1',
			short: 'action'
		},
		ARCADE: {
			name: 'Arcade',
			icon: 'gamepad',
			id: 'ec499226-0913-4db1-890e-093b366bcb3c',
			short: 'arcade'
		},
		BULLETHELL: {
			name: 'Bullet Hell',
			icon: 'rocket',
			id: '7f8676fe-4900-400b-9284-c073388d88f7',
			short: 'bh'
		},
		FPS: {
			name: 'First-Person',
			icon: 'crosshairs',
			id: 'a6d00cc0-9481-47cb-bb52-a7011041915a',
			short: 'fps'
		},
		PLATFORMER: {
			name: 'Platformer',
			icon: 'running',
			id: '3c3fd562-525c-4e24-a1fa-5b5eda85ebbd',
			short: 'platformer'
		},
		STRATEGY: {
			name: 'Strategy',
			icon: 'chess-rook',
			id: 'ffb558c1-5a45-4b8c-856c-e9622ce54f00',
			short: 'strategy'
		}
	},
	GROUPS: {
		ADMINISTRATOR: {
			id: '9132b544-0229-46cf-82f1-7d37f60678cb'
		},
		MODERATOR: {
			id: 'd87b2735-0020-4399-8c41-5dd153d6c716'
		},
		EXCLUDED: {
			id: '906cb567-ff1e-4190-9a6c-f44e6d1a0910'
		}
	},
	PLATFORMS: {
		STEAM: {
			//id: later
			name: 'Steam',
			compat: 'steam',
			short: 'steam'
		},
		RETROACHIEVEMENTS: {
			name: 'RetroAchievements',
			compat: 'retroachievements',
			short: 'ra'
		}
	},
	RANKS: [
		{
			color: '#979C9F',
			name: 'E',
			value: 0
		},
		{
			color: '#905F00',
			name: 'D',
			value: 50
		},
		{
			color: '#2ECC71',
			name: 'C',
			value: 250
		},
		{
			color: '#3498DB',
			name: 'B',
			value: 500
		},
		{
			color: '#9B59B6',
			name: 'A',
			value: 1000
		},
		{
			color: '#E67E22',
			name: 'S',
			value: 2500
		},
		{
			color: '#F1C40F',
			name: 'SS',
			value: 5000
		},
		{
			color: '#E74C3C',
			name: 'SSS',
			value: 7500
		},
		{
			color: '#444444',
			name: 'EX',
			value: 10000
		}
	],
	TIERS: [
		{
			name: 'Untiered',
			shortName: 'T?',
			intent: Intent.NONE
		},
		{
			name: 'Tier 1',
			shortName: 'T1',
			intent: Intent.PRIMARY
		},
		{
			name: 'Tier 2',
			shortName: 'T2',
			intent: Intent.SUCCESS
		},
		{
			name: 'Tier 3',
			shortName: 'T3',
			intent: Intent.WARNING
		},
		{
			name: 'Tier 4',
			shortName: 'T4',
			intent: Intent.DANGER
		},
		{
			name: 'Tier 5',
			shortName: 'T5',
			intent: Intent.DANGER
		}
	],
	UNIVERSAL_GENRE_ID: '00000000-0000-0000-0000-000000000000'
};