import { MenuItem } from '@blueprintjs/core';
import { Suggest2 } from '@blueprintjs/select';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoResults = (props) => {
	const {t} = useTranslation();
	if(props.query && props.query !== '') return <MenuItem disabled={true} text={t('App:nav.search.noResults')} roleStructure="listoption" />
	return <MenuItem disabled={true} text={t('App:nav.search.placeholder2')} roleStructure="listoption" />
}

const renderResult = (item, props) => {
	return (
		<MenuItem
			active={props.modifiers.active}
			disabled={props.modifiers.disabled}
			icon={<img src={item.icon} style={{borderRadius: '1rem'}} width="20" />}
			key={item.id}
			label={item.type[0].toUpperCase() + item.type.slice(1).toLowerCase()}
			onClick={props.handleClick}
			roleStructure="listoption"
			text={<>{item.name}</>}
		/>
	);
};

export function Search(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [activeItem, setActiveItem] = useState(null);
	const [query, setQuery] = useState(null);
	const [searchState, setSearchState] = useState([]);

	useEffect(() => {
		const search = async () => {
			if(!query || query === '') return setSearchState([]);
			const result = await axios.post('/api/search', {
				query: query
			});

			setSearchState(result.data);
			if(result.data.length) setActiveItem(result.data[0]);
		};

		search();
	}, [query]);

	return (
		<Suggest2
			activeItem={activeItem}
			inputProps={{
				leftIcon: 'search',
				placeholder: `${t('App:nav.search.placeholder')}`,
				round: true
			}}
			inputValueRenderer={item => item.name}
			itemRenderer={renderResult}
			items={searchState}
			noResults={<NoResults query={query} />}
			onActiveItemChange={item => setActiveItem(item)}
			onItemSelect={item => navigate(item.url)}
			onQueryChange={query => setQuery(query)}
			resetOnSelect
		/>
	);
}