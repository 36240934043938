import {Position, Toaster} from '@blueprintjs/core';
import { createRoot } from 'react-dom/client';

export let AppToaster: Toaster;

createRoot(document.getElementById('toaster')!).render(
  <Toaster
    className="Achoo-Toaster"
    position={Position.TOP}
    ref={instance => {
      AppToaster = instance!;
    }}
  />
);