import * as React from 'react';

import {Classes, Callout, Code} from '@blueprintjs/core';
import Container from './Container';

interface ErrorBoundaryProps {}
interface ErrorBoundaryState {
	error: any,
	hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			hasError: false
		}
	}

	static getDerivedStateFromError(error) {
		return {
			error: error,
			hasError: true
		}
	}

	componentDidCatch(error, errorInfo) {
		// console.log(error);
	}

	render() {
		if(this.state.hasError) {
			return (
				<div className={Classes.DARK}>
					<Container>
						<br />
						<Callout intent="danger" title="Achoo has crashed.">
							Not a big surprise honestly, it's still an alpha.
							<br />
							Check the console for more details.
						</Callout>
					</Container>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;