import { Common } from './common';

export function createHelmetTitle(page?, subpage?) {
	if(page && subpage) return `${subpage} :: ${page} :: CE`;
	if(page && !subpage) return `${page} :: CE`;
	if(!page && !subpage) return 'CE';
}

export function findGenreById(genreId) {
	return Common.GENRES[Object.keys(Common.GENRES).find(genre => Common.GENRES[genre].id === genreId)];
}

export function findPlatformById(platformId) {
	return Common.PLATFORMS[Object.keys(Common.PLATFORMS).find(platform => Common.PLATFORMS[platform].compat === platformId)];
} //it uses compat!!!! to take into account when changing to id later

export function isModerator(user) {
	const moderatorGroups = [
		Common.GROUPS.ADMINISTRATOR.id,
		Common.GROUPS.MODERATOR.id
	];

	return !!user.userGroups.find(g => moderatorGroups.includes(g.groupId));
}

export function parseVibrantData(d = null, raw = false) {
	let data = {...d};
	if(!data.vibrant) data = {
		vibrant: '#000000',
		darkVibrant: '#383e47',
		lightVibrant: '#ffffff',
		muted: '#cccccc'
	};
	if(!data.darkVibrant) data.darkVibrant = '#383e47';
	if(!data.lightVibrant) data.lightVibrant = '#ffffff';

	const vars = {
		'--color-vibrant': data.vibrant,
		'--color-vibrant-opacity-75': `${data.vibrant}c0`,
		'--color-vibrant-opacity-50': `${data.vibrant}80`,
		'--color-vibrant-opacity-30': `${data.vibrant}4d`,
		'--color-vibrant-dark': data.darkVibrant,
		'--color-vibrant-dark-opacity-75': `${data.darkVibrant}c0`,
		'--color-vibrant-dark-opacity-50': `${data.darkVibrant}80`,
		'--color-vibrant-dark-opacity-30': `${data.darkVibrant}4d`,
		'--color-vibrant-light': data.lightVibrant,
		'--color-vibrant-light-opacity-75': `${data.lightVibrant}c0`,
		'--color-vibrant-light-opacity-50': `${data.lightVibrant}80`,
		'--color-vibrant-light-opacity-30': `${data.lightVibrant}4d`,
		'--color-muted': data.muted,
		'--color-muted-opacity-75': `${data.muted}c0`,
		'--color-muted-opacity-50': `${data.muted}80`,
		'--color-muted-opacity-30': `${data.muted}4d`
	};

	if(raw) return vars;

	return ':root {\n' + Object.keys(vars).map((v) => {
		const val = vars[v];
    
		return `  ${v}: ${val};`;
	}).join('\n') + '}';
}