import * as React from 'react';

const Container = props => {
	return (
		<div className={props.wide ? "container-wide" : "container"}>
			{props.children}
		</div>
	)
}

export default Container;