import * as React from 'react';
import * as ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export function withAnalytics(Component) {
	return props => {
		let l = useLocation();

		if(!location.hostname.startsWith('localhost') && !location.hostname.startsWith('127.0.0.1')) {
			React.useEffect(() => {
				ReactGA.set({ page: l.pathname });
				ReactGA.pageview(l.pathname);

				ReactGA4.send({
					hitType: 'pageview',
					page: l.pathname
				});
			}, [l]);
		}
  
		return <Component {...props} />
	}
}

export function withEverything(Component) {
	return props => <Component {...props} location={useLocation()} navigate={useNavigate()} params={useParams()} />
}

export function withLocation(Component) {
	return props => <Component {...props} location={useLocation()} />
}

export function withNavigate(Component) {
	return props => <Component {...props} navigate={useNavigate()} />
}

export function withParams(Component) {
	return props => <Component {...props} params={useParams()} />
}