import i18n from 'i18next';
// import resources from 'virtual:i18next-loader';
import { initReactI18next } from 'react-i18next';

import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocalStorageBackend from 'i18next-localstorage-backend';

const config = {
	backend: {
		backends: [
			LocalStorageBackend,
			HttpBackend
		],
		backendOptions: [
			{
				defaultVersion: `v${ACHOO_RELEASE_VERSION}`,
				expirationTime: 7 * 24 * 60 * 60 * 1000
			},
			{
				loadPath: '/locales/{{lng}}/{{ns}}.json'
			}
		]
	},
	debug: import.meta.env.DEV,
	defaultNS: 'App',
	fallbackLng: 'en-GB',
	interpolation: {
		escapeValue: false
	},
	load: 'currentOnly',
	ns: [
		'App',
		'pages/GamePage',
		'pages/GamesPage',
		'pages/LeaderboardsPage',
		'pages/UserPage'
	],
	// resources,
	supportedLngs: [
		// 'ar-SA',
		'cs-CZ',
		'de-DE',
		'en-GB',
		// 'en-PT',
		'es-ES',
		'fi-FI',
		'fr-FR',
		// 'he-IL',
		'it-IT',
		'ja-JP',
		'ko-KR',
		// 'nl-NL',
		'pl-PL',
		'pt-BR',
		'pt-PT',
		'ru-RU',
		// 'tr-TR',
		'uk-UA',
		'zh-TW'
	]
}

if(import.meta.env.DEV) {
	config.backend = {
		backends: [HttpBackend],
		backendOptions: [
			{
				loadPath: '/locales/{{lng}}/{{ns}}.json'
			}
		]
	}
}

i18n
	.use(ChainedBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init(config);

export default i18n;