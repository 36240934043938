import axios from 'axios';
import * as React from 'react';
import cls from 'classnames';
import {Link, Outlet} from 'react-router-dom';

import {Alignment, AnchorButton, Button, Classes, Intent, Menu, MenuDivider, MenuItem, Navbar, Position, Spinner, SpinnerSize} from '@blueprintjs/core';
import {Popover2, Tooltip2} from '@blueprintjs/popover2';

import {withAnalytics, withEverything} from './hoc';

import IAuth from './components/interfaces/IAuth';

import {AppToaster} from './components/AppToaster';
import AuthContext from './components/AuthContext';
import {Search} from './components/search/Search';
import Container from './components/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { createHelmetTitle, isModerator, parseVibrantData } from './utilities';
import { withTranslation } from 'react-i18next';

interface AppProps {
	i18n: any,
	location: any,
	navigate: any,
	t: Function
}

interface AppState {
	auth: IAuth,
	easterEggStatus: number,
	isEasterEggOpen: boolean,
	loading: boolean
}

const EASTER_EGG_ENTRIES = [
	{
		value: 3,
		tooltip: {
			intent: Intent.NONE,
			message: 'There are no easter eggs here.'
		}
	},
	{
		value: 10,
		tooltip: {
			intent: Intent.NONE,
			message: 'No, like seriously. You can stop now.'
		}
	},
	{
		value: 15,
		tooltip: {
			intent: Intent.NONE,
			message: 'Dude. Don\'t you have something better to do?'
		}
	},
	{
		value: 20,
		tooltip: {
			intent: Intent.WARNING,
			message: 'It fucking hurts, you know.'
		}
	},
	{
		value: 25,
		tooltip: {
			intent: Intent.NONE,
			message: 'Go play fucking N++ or whatever you nerds enjoy nowadays.'
		}
	},
	{
		value: 30,
		tooltip: {
			intent: Intent.NONE,
			message: 'Seriously, what do you want from me?'
		}
	},
	{
		value: 35,
		tooltip: {
			intent: Intent.WARNING,
			message: 'What, you want to hear the line?'
		}
	},
	{
		value: 40,
		tooltip: {
			intent: Intent.WARNING,
			message: 'Is that gonna make you go away?'
		}
	},
	{
		value: 45,
		tooltip: {
			intent: Intent.WARNING,
			message: 'Sigh, fine...'
		}
	},
	{
		value: 50,
		tooltip: {
			intent: Intent.DANGER,
			message: 'Go die in a hole.'
		}
	},
	{
		value: 60,
		tooltip: {
			intent: Intent.DANGER,
			message: 'Light mode. Welcome to hell.'
		}
	}
];

const LANGUAGES = {
	'en-GB': {
		icon: 'gb',
		name: 'English'
	},
	'zh-TW': {
		icon: 'tw',
		label: '53%',
		name: 'Chinese Traditional (繁體中文)'
	},
	'cs-CZ': {
		icon: 'cz',
		label: '53%',
		name: 'Czech (Čeština)'
	},
	'de-DE': {
		icon: 'de',
		label: '53%',
		name: 'German (Deutsch)'
	},
	'fi-FI': {
		icon: 'fi',
		label: '74%',
		name: 'Finnish (Suomi)'
	},
	'fr-FR': {
		icon: 'fr',
		label: '72%',
		name: 'French (Français)'
	},
	'it-IT': {
		icon: 'it',
		label: '48%',
		name: 'Italian (Italiano)'
	},
	'ja-JP': {
		icon: 'jp',
		label: '56%',
		name: 'Japanese (日本語)'
	},
	'ko-KR': {
		icon: 'kr',
		label: '53%',
		name: 'Korean (한국어)'
	},
	'pl-PL': {
		icon: 'pl',
		label: '53%',
		name: 'Polish (Polski)'
	},
	'pt-PT': {
		icon: 'pt',
		label: '38%',
		name: 'Portuguese (Português)'
	},
	'pt-BR': {
		icon: 'br',
		label: '53%',
		name: 'Portuguese, Brazilian (Português)'
	},
	'ru-RU': {
		icon: 'ru',
		label: '53%',
		name: 'Russian (Русский)'
	},
	'es-ES': {
		icon: 'es',
		label: '53%',
		name: 'Spanish (Español)'
	},
	'tr-TR': {
		icon: 'tr',
		label: '51%',
		name: 'Turkish (Türkçe)'
	},
	'uk-UA': {
		icon: 'ua',
		label: '53%',
		name: 'Ukrainian (Українська)'
	}
};

class App extends React.Component<AppProps, AppState> {
	constructor(props) {
		super(props);

		this.state = {
			auth: {
				user: null
			},
			easterEggStatus: 0,
			isEasterEggOpen: false,
			loading: true
		};

		this.easterEgg = this.easterEgg.bind(this);
		this.easterEggClose = this.easterEggClose.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.handleModLink = this.handleModLink.bind(this);
		this.handleMyGamesLink = this.handleMyGamesLink.bind(this);
		this.handleMyProfileLink = this.handleMyProfileLink.bind(this);
		this.handleSettingsLink = this.handleSettingsLink.bind(this);
		this.logout = this.logout.bind(this);
		this.scheduleUpdate = this.scheduleUpdate.bind(this);
		this.startAuth = this.startAuth.bind(this);
	}

	componentDidMount() {
		this.getAuth();
	}

	easterEgg() {
		if(this.props.location.pathname != '/') return;

		const count = this.state.easterEggStatus + 1;

		this.setState({
			easterEggStatus: count,
			isEasterEggOpen: count >= 3
		});

		if(count >= 50) {
			document.getElementsByTagName('html')[0].style.backgroundColor = 'white';
		}
	}

	easterEggClose() {
		this.setState({
			isEasterEggOpen: false
		});
	}

	get easterEggEntry() {
		let result: any = {
			value: 0,
			tooltip: {
				intent: Intent.NONE,
				message: ''
			}
		};

		for(const entry of EASTER_EGG_ENTRIES) {
			if(this.state.easterEggStatus >= entry.value) {
				result = entry;
			}
		}

		return result;
	}

	getAuth() {
		axios.get('/api/auth').then((response) => {
			// localStorage.setItem('loggedIn', response.data.user ? 'true' : 'false');

			this.setState({
				auth: response.data,
				loading: false
			});
		});
	}

	handleLanguageChange(lang) {
		this.props.i18n.changeLanguage(lang);
		// location.reload();
	}

	handleModLink(event) {
		if(!this.state.auth.user || !isModerator(this.state.auth.user)) return;

		this.props.navigate('/mod');
	}

	handleMyGamesLink(event) {
		if(!this.state.auth.user) return;

		this.props.navigate('/user/' + this.state.auth.user.id + '/games');
	}

	handleMyProfileLink(event) {
		if(!this.state.auth.user) return;

		this.props.navigate('/user/' + this.state.auth.user.id);
	}

	handleSettingsLink(event) {
		if(!this.state.auth.user) return;

		this.props.navigate('/settings');
	}

	get isDev() {
		return location.hostname.startsWith('localhost') || location.hostname.startsWith('127.0.0.1');
	}

	logout() {
		axios.post('/logout').then((response) => {
			this.getAuth();
		});
	}

	scheduleUpdate() {
		if(!this.state.auth.user) return;

		axios.post(`/api/user/${this.state.auth.user.id}/update`).then((response) => {
			AppToaster.show({
				intent: Intent.SUCCESS,
				message: 'Profile update has been scheduled.'
			});
		}).catch((error) => {
			let message = error.message;
			
			switch(error.response.status) {
				case 429:
					message = 'You\'re updating too quickly. Wait like 15 minutes and try again.';
					break;
			}

			console.log(error);
			console.log(error.response);

			AppToaster.show({
				intent: Intent.DANGER,
				message: message
			});
		});
	}
	
	startAuth() {
		localStorage.setItem('auth.redirect', this.props.location.pathname);
	}

	render() {
		const t = this.props.t;

		return (<>
			<Helmet>
				<title>{createHelmetTitle()}</title>
				<style id="css-app" type="text/css">{parseVibrantData()}</style>
			</Helmet>
			<AuthContext.Provider value={this.state.auth}>
				<div className={cls({
					[Classes.DARK]: this.state.easterEggStatus < 50,
					[`${Classes.getClassNamespace()}-hell`]: this.state.easterEggStatus >= 50
				})}>
					<Navbar
						className={cls({
							'bp4-elevation-4': true,
							[`${Classes.NAVBAR}--dev`]: this.isDev,
							[`${Classes.NAVBAR}--home`]: this.props.location.pathname === '/'
						})}
						fixedToTop={true}
					>
						<Container>
							<Navbar.Group>
								<Tooltip2 content={this.easterEggEntry.tooltip.message} intent={this.easterEggEntry.tooltip.intent} isOpen={this.state.isEasterEggOpen} onClose={this.easterEggClose} position={Position.LEFT}>
									<Navbar.Heading>
										<Link to="/" className="mr-2" style={{ alignItems: 'center', display: 'flex' }}>
											{/* <div className="mask"></div> */}
											<img className="mr-4" onClick={this.easterEgg} src="/images/logo_mask.png" width="24" />
											<span>Challenge Enthusiasts</span>
										</Link>
									</Navbar.Heading>
								</Tooltip2>
								<Navbar.Divider />
								<Link to="/games">
									<Button minimal text={t('App:pages.games')} />
								</Link>
								<Link to="/leaderboards">
									<Button minimal text={t('App:pages.leaderboards')} />
								</Link>
								{/* <Link to="/about">
									<Button minimal text={t('App:pages.about')} />
								</Link> */}
								<Navbar.Divider />
								<Tooltip2 content={t('nav.donate.tooltip')} position={Position.BOTTOM}>
									<AnchorButton href="https://streamelements.com/lauriys/tip" icon="bank-account" intent={Intent.PRIMARY} minimal />
								</Tooltip2>
								<Navbar.Divider />
								<AnchorButton href="https://discord.gg/spKdVZTZ6c" icon={<FontAwesomeIcon icon={['fab', 'discord']} />} minimal />
								<AnchorButton href="https://steamcommunity.com/groups/cedb" icon={<FontAwesomeIcon icon={['fab', 'steam']} />} minimal />
							</Navbar.Group>
							<Navbar.Group align={Alignment.RIGHT}>
								{/* <Autocomplete
									detachedMediaQuery=""
									openOnFocus={true}
								/> */}
								<Search />
								<Navbar.Divider />
								<Popover2
									content={
										<Menu className="Themed" key="language">
											{Object.keys(LANGUAGES).map((langCode) => {
												let lang = LANGUAGES[langCode]

												return <MenuItem
													icon={<img src={`/images/flags/${lang.icon}.png`} />}
													intent={!!lang.beta ? Intent.WARNING : Intent.NONE}
													key={langCode}
													label={!!lang.label ? lang.label : (!!lang.beta ? 'Beta' : '')}
													onClick={() => this.handleLanguageChange(langCode)}
													text={lang.name}
												/>
											})}
											<MenuDivider />
											<MenuItem icon="translate" href="https://crowdin.com/project/achoo" target="_blank" text="Contribute on Crowdin!" />
										</Menu>
									}
									popoverClassName="Themed"
								>
									<Button icon={<img src={`/images/flags/${LANGUAGES[this.props.i18n.language]?.icon || 'fam'}.png`} />} minimal />
								</Popover2>
								<Navbar.Divider />
								{this.state.loading ? <Button minimal loading /> : (
									this.state.auth.user ? (
										<Popover2
											content={
												<Menu className="Themed" key="menu">
													<MenuItem icon="user" onClick={this.handleMyProfileLink} text={t('nav.user.profile')} />
													<MenuItem icon={<FontAwesomeIcon icon="gamepad" />} onClick={this.handleMyGamesLink} text={t('nav.user.games')} />
													<MenuDivider />
													<MenuItem icon="refresh" onClick={this.scheduleUpdate} text={t('nav.user.update')} />
													<MenuDivider />
													<MenuItem icon="cog" onClick={this.handleSettingsLink} text={t('nav.user.settings')} />
													{isModerator(this.state.auth.user) ? <MenuItem onClick={this.handleModLink} text={'Mod Actions'} /> : <></>}
													<MenuDivider />
													<MenuItem icon="log-out" onClick={this.logout} text={t('nav.user.logout')} />
												</Menu>
											}
											popoverClassName="Themed"
										>
											<Button minimal icon={<img className="avatar" height="16" width="16" src={this.state.auth.user.avatar} loading="lazy" />} text={this.state.auth.user.displayName} rightIcon="caret-down" />
										</Popover2>
									) : (
										<AnchorButton minimal href="/auth/steam" onClick={this.startAuth}>{t('nav.login.steam')}</AnchorButton>
									)
								)}
							</Navbar.Group>
						</Container>
					</Navbar>

					{this.state.loading ? (
						<Container>
							<Spinner size={SpinnerSize.SMALL} />
						</Container>
					) : (
						<Outlet />
					)}
				</div>
			</AuthContext.Provider>
		</>)
	}
}

export default withAnalytics(withEverything(withTranslation()(App)));