import axios from 'axios';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import * as ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import * as Sentry from '@sentry/react';

import 'vite/modulepreload-polyfill';

import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {FocusStyleManager} from '@blueprintjs/core';
import {HelmetProvider} from 'react-helmet-async';
import {I18nextProvider} from 'react-i18next';
import {SWRConfig} from 'swr';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {faDiscord, faSteam} from '@fortawesome/free-brands-svg-icons';
import {faAward, faChessRook, faCircleCheck, faCrosshairs, faDragon, faGamepad, faHandFist, faInfinity, faMedal, faRocket, faRunning, faSpinner, faSplotch, faStar, faTrophy} from '@fortawesome/free-solid-svg-icons';

Sentry.init({
	dsn: 'https://9ef201ba27684b3cbb7ffd6a72b16e2c@o4505417001271296.ingest.sentry.io/4505417003171840',
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: ['localhost', /^https:\/\/cedb\.me\/api/]
		}),
		new Sentry.Replay({
			networkDetailAllowUrls: [window.location.origin],
			useCompression: false
		})
	],
	release: 'achoo-web@' + ACHOO_RELEASE_VERSION,
	tracesSampleRate: 0.1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

import i18n from './i18n';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingFallback from './components/LoadingFallback';

const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const AuthRedirectPage = React.lazy(() => import('./pages/AuthRedirectPage'));
const GamePage = React.lazy(() => import('./pages/GamePage'));
const GamesPage = React.lazy(() => import('./pages/GamesPage'));
const IndexPage = React.lazy(() => import('./pages/IndexPage'));
const LeaderboardsPage = React.lazy(() => import('./pages/LeaderboardsPage'));
const ModPage = React.lazy(() => import('./pages/ModPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const UserPage = React.lazy(() => import('./pages/UserPage'));

import './styles/app.scss';

const UA_TRACKING_ID = 'UA-235221253-1';
const GA4_TRACKIND_ID = 'G-EF2YN3VFCD';

FocusStyleManager.onlyShowFocusOnTabs();
library.add(faAward);
library.add(faChessRook);
library.add(faCircleCheck);
library.add(faCrosshairs);
library.add(faDiscord);
library.add(faDragon);
library.add(faGamepad);
library.add(faHandFist);
library.add(faInfinity);
library.add(faMedal);
library.add(faRocket);
library.add(faRunning);
library.add(faSpinner);
library.add(faSplotch);
library.add(faStar);
library.add(faSteam);
library.add(faTrophy);

ReactGA.initialize(UA_TRACKING_ID);
ReactGA4.initialize(GA4_TRACKIND_ID);

const container = document.getElementById('app');
const root = createRoot(container!);

const queryClient = new QueryClient();

root.render(
	<BrowserRouter>
		<ErrorBoundary>
			<React.Suspense fallback={<LoadingFallback />}>
				<HelmetProvider>
					<I18nextProvider i18n={i18n}>
						<QueryClientProvider client={queryClient}>
							<SWRConfig value={{
								fetcher: (url) => axios.get(url).then(res => res.data)
							}}>
								<Routes>
									<Route path="/" element={<App />}>
										<Route index element={<React.Suspense fallback={<LoadingFallback />}><IndexPage /></React.Suspense>} />
										<Route path="about" element={<React.Suspense fallback={<LoadingFallback />}><AboutPage /></React.Suspense>} />
										<Route path="auth" element={<React.Suspense fallback={<LoadingFallback />}><AuthRedirectPage /></React.Suspense>} />
										<Route path="game/:gameId" element={<React.Suspense fallback={<LoadingFallback />}><GamePage /></React.Suspense>} />
										<Route path="games" element={<React.Suspense fallback={<LoadingFallback />}><GamesPage /></React.Suspense>} />
										<Route path="leaderboards" element={<React.Suspense fallback={<LoadingFallback />}><LeaderboardsPage /></React.Suspense>} />
										<Route path="leaderboards/:genreId" element={<React.Suspense fallback={<LoadingFallback />}><LeaderboardsPage /></React.Suspense>} />
										<Route path="leaderboards/:genreId/tiers/:tierId" element={<React.Suspense fallback={<LoadingFallback />}><LeaderboardsPage /></React.Suspense>} />
										<Route path="leaderboards/tiers/:tierId" element={<React.Suspense fallback={<LoadingFallback />}><LeaderboardsPage /></React.Suspense>} />
										<Route path="mod" element={<React.Suspense fallback={<LoadingFallback />}><ModPage /></React.Suspense>} />
										<Route path="settings" element={<React.Suspense fallback={<LoadingFallback />}><SettingsPage /></React.Suspense>} />
										<Route path="user/:userId/*" element={<React.Suspense fallback={<LoadingFallback />}><UserPage /></React.Suspense>} />
										<Route path="*" element={<React.Suspense fallback={<LoadingFallback />}><NotFoundPage /></React.Suspense>} />
									</Route>
								</Routes>
							</SWRConfig>
						</QueryClientProvider>
					</I18nextProvider>
				</HelmetProvider>
			</React.Suspense>
		</ErrorBoundary>
	</BrowserRouter>
);

dom.watch();